import BaseModule from './utils/BaseModule'
class BaseManager extends BaseModule {
	constructor() {
		super()
	}

	//用户列表
	userList(param) {
		return this.get('web/userInfo/list', param)
	}
	//用户积分明细
	integralList(param) {
		return this.get('web/integralChangeInfo/list', param)
	}
	//禁用/解禁
	disableUser(param) {
		return this.post('web/userInfo/disable', param)
	}
	//设置用户为官方用户
	setOfficial(param) {
		return this.post('web/userInfo/setOfficial', param)
	}
	//设置用户为推荐用户
	setRecommend(param) {
		return this.post('web/userInfo/setRecommend', param)
	}
	// 设置用户身份
	setUserType(param) {
		return this.post('web/userInfo/setUserType', param)
	}
	// 二期 设置主管/取消
	setUserTypeSupervisor(param) {
		return this.post('web/userInfo/setOrCancelSupervisor', param)
	}
	//修改积分
	changeIntegral(param) {
		return this.post('web/integralChangeInfo/changeIntegral', param)
	}
	//添加用户
	addUser(param) {
		return this.post('web/userInfo/save', param)
	}


	// ------------------------- 2024-05-20 二期新增 -----------------------------------------------------------------------------------------------
	//职业信息列表
	occupationList(param) {
		return this.get('/web/occupationInfo/list', param)
	}
	//职业信息删除
	occupationRemove(param) {
		return this.post('/web/occupationInfo/remove', param)
	}

	//余额变动记录列表
	balanceChangeList(param) {
		return this.get('/web/balanceChangeRecord/list', param)
	}
	// //余额变动记录删除
	// balanceChangeRemove(param) {
	// 	return this.post('/web/balanceChangeRecord/remove', param)
	// }

	//提现记录列表
	withdrawalList(param) {
		return this.get('/web/withdrawalRecord/list', param)
	}
	// //提现记录删除
	// withdrawalRemove(param) {
	// 	return this.post('/web/withdrawalRecord/remove', param)
	// }
	//用户画像标签
	getUserMessage(param) {
		return this.get('/web/label/getUserMessage', param)
	}
	// 同步企业微信标签
	updateLabel(param) {
		return this.post('web/label/updateLabel', param)
	}






}

export default new BaseManager()

import request from '@/request/utils/newrequest.js'
import { baseconfig } from "@/config.js";

//职业信息
export const occupationModule = {
	// 添加职业信息
	async saveOccupation(param) {
		return await request({
			url: baseconfig.baseUrl + "/web/occupationInfo/save",
			method: 'post',
			data: param
		})
	},
	// 修改职业信息
	async updateOccupation(param) {
		return await request({
			url: baseconfig.baseUrl + "/web/occupationInfo/update",
			method: 'post',
			data: param
		})
	},

}

//提现记录
export const withdrawalModule = {
	// 审核提现记录
	async auditWithdrawal(param) {
		return await request({
			url: baseconfig.baseUrl + "/web/withdrawalRecord/audit",
			method: 'post',
			data: param
		})
	},

}