<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s" v-if="!(this.userId>0)">
                <el-form-item>
                    <el-select size="small" v-model="query.userType" placeholder="请选择用户类型" clearable>
                        <el-option v-for="item in userTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item>
                    <el-select size="small" v-model="query.changeType" placeholder="请选择变动类型" clearable>
                        <el-option v-for="item in changeTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'balanceChangeRecordId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="balanceChangeRecordId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="nickName" label="用户名称" align="center"></el-table-column>
                <!-- <el-table-column prop="isIncome" label="是否收入" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isIncome == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column> -->
                <el-table-column prop="userType" label="用户类型" align="center">
                    <template slot-scope="scope">
                        {{scope.row.userType*1 | getStateName(userTypeList)}}
                    </template>
                </el-table-column>
                <el-table-column prop="changeContent" label="变动内容" align="center">
                    <!-- <template slot-scope="scope">
                        <el-link type="primary" @click="rowContent(scope.row)">详情</el-link>
                    </template> -->
                </el-table-column>
                <el-table-column prop="beforeBalance" label="变动前余额" align="center"></el-table-column>
                <el-table-column prop="changeAmount" label="变动金额" align="center"></el-table-column>
                <el-table-column prop="afterBalance" label="变动后余额" align="center"></el-table-column>
                <el-table-column prop="changeTime" label="变动时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.changeTime | formatDate }}
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import userManager from "@/request/userManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
export default {
    mixins: [getList],
    props: ["userId"],
    components: {
        ShowContent,
    },
    data() {
        return {
            requst: {
                module: userManager,
                url: 'balanceChangeList',
            },
            query: {
                userId: this.userId?this.userId:'',
                userType: '',
            },
            // 用户类型（2美容师、4主管、3代理商、1总部）
            userTypeList: [
                {value: 1,label: "总部",},
                {value: 2,label: "美容师",},
                {value: 3,label: "代理商",},
                {value: 4,label: "主管",},
            ],
            isIncomeList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            dialogSeeContent: false,
            rowInfo: {
                title: '',
                content: '',
            },

        };
    },
    created() {

    },
    methods: {
        rowContent(row) {
            this.rowInfo.title = "变动内容";
            this.rowInfo.content = row.changeContent;
            this.dialogSeeContent = true;
        },
    },
};
</script>

<style scoped>
</style>
