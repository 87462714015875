<template>
  <div class="container">
    <div class='page-container'>
      <div class="search-box">
        <el-form :inline="true" class="fr-s-s">
          <el-form-item>
            <el-input size="small" v-model="query.username" clearable placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" plain type="primary" :loading="showLoading" @click="refreshData">查询</el-button>
          </el-form-item>
          <el-form-item v-if="permission.saveAgent">
            <el-button size="small" plain type="primary" :loading="showLoading" @click="onAdd">添加</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-box">
        <el-table ref="table" :data="tableData" border :row-key="'aid'" v-loading="showLoading" :height="tableHeight">
          <el-table-column prop="advertID" label="编号" align="center" width="60">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="name" label="姓名" min-width="120" align="center">
            <template slot-scope="scope">{{ scope.row.name || '--' }}</template>
          </el-table-column>
          <el-table-column prop="username" label="用户名" min-width="120" align="center">
            <template slot-scope="scope">{{ scope.row.username || '--' }}</template>
          </el-table-column>
          <el-table-column prop="mobile" label="手机号" min-width="100" align="center">
            <template slot-scope="scope">{{ scope.row.mobile || '--' }}</template>
          </el-table-column>
          <el-table-column prop="email" label="邮箱" min-width="160" align="center">
            <template slot-scope="scope">{{ scope.row.email || '--' }}</template>
          </el-table-column>
          <!-- 2024-05-22二期新增 -->
          <el-table-column prop="accumulatedIncome" label="累积收益" align="center">
            <template slot-scope="scope">
              <a @click="userAccumulatedIncomeHandle(scope.row)">
                <el-link type="primary">{{ scope.row.accumulatedIncome ? scope.row.accumulatedIncome : 0 }}</el-link>
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="withdrawableIncome" label="可提现收益" align="center">
            <template slot-scope="scope">
              <span type="primary">{{ scope.row.withdrawableIncome ? scope.row.withdrawableIncome : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="withdrawalIncome" label="提现收益" align="center">
            <template slot-scope="scope">
              <a @click="userWithdrawalIncomeHandle(scope.row)">
                <el-link type="primary">{{ scope.row.withdrawalIncome ? scope.row.withdrawalIncome : 0 }}</el-link>
              </a>
            </template>
          </el-table-column>

          <el-table-column prop="roleSigns" label="角色" min-width="120" align="center">
            <template slot-scope="scope">{{ scope.row.roleList | formatRule }}</template>
          </el-table-column>
          <el-table-column label="是否可用" width="80" align="center">
            <template slot-scope="scope">
              <i :class="[scope.row.status == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140" fixed="right" align="center">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="permission.updateAgent">
                <el-button class="success" icon="el-icon-edit-outline" type="text"
                  @click="clickEditInfo(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeAgent">
                <el-popconfirm title="您确定要删除么？" icon="el-icon-info" icon-color="red"
                  @confirm="clickDelInfo(scope.row, scope.$index)">
                  <el-button icon="el-icon-delete" slot="reference" type="text" class="danger"></el-button>
                </el-popconfirm>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="修改密码" placement="top" v-if="permission.resetPwd">
                <el-button class="success" icon="el-icon-key" type="text"
                  @click="onEditAdminPwd(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="选择" placement="top" v-if="permission.selectAgent">
                <el-button icon="el-icon-circle-check" type="text" class="success"
                  @click="selectAgentHandle(scope.row)"></el-button>
              </el-tooltip>
              <!-- <el-tooltip class="item" effect="dark" content="实名认证" placement="top" v-if="permission.updateAgent">
                <el-button class="success" icon="el-icon-s-custom" type="text"
                  @click="clickAuthInfo(scope.row)"></el-button>
              </el-tooltip> -->
            </template>
          </el-table-column>
        </el-table>

        <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
        </el-pagination>

      </div>
    </div>

    <el-dialog :title="`${addOrEditModel.userId ? '编辑' : '添加'}代理商`" :visible.sync="addOrEditDialog" :modal="false"
      :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form class="form-wrap" label-position="left" label-width="100px" ref="addOrEditModel" :model="addOrEditModel"
        :rules="addOrEditRules">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addOrEditModel.name" prefix-icon="el-icon-user" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addOrEditModel.username" prefix-icon="el-icon-user" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input maxlength="11" oninput="value=value=value.replace(/[^0-9]/g,'')" v-model="addOrEditModel.mobile"
            prefix-icon="el-icon-phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password" v-if="!addOrEditModel.userId">
          <el-input v-model="addOrEditModel.password" prefix-icon="el-icon-key" show-password
            placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="repeatPwd" class="is-required" v-if="!addOrEditModel.userId">
          <el-input v-model="addOrEditModel.repeatPwd" prefix-icon="el-icon-key" show-password
            placeholder="请重复登录密码"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addOrEditModel.email" prefix-icon="el-icon-message" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="是否可用" required>
          <el-checkbox v-model="addOrEditModel.status" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
        <!-- <el-form-item label="所属角色" prop="roleIds">
          <el-checkbox-group v-model="checkList">
            <el-checkbox v-for="(i,v) in rolelist" :key="v" :label="i.roleId">{{i.roleName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" :loading="showLoading" @click="clickAddOrEditConfirm">确定</el-button>
          <el-button class="m-l-10" @click="resetAddOrEditData">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :title="`修改密码`" :visible.sync="dialogEditAdminPwdFormVisible" :modal="false" :destroy-on-close="true"
      fullscreen :close-on-click-modal="false">
      <el-form ref="editPwd" label-width="120px" :model="addOrEditModel" :rules="addOrEditRules">
        <el-form-item label="登录密码" prop="password" class="is-required">
          <el-input v-model="addOrEditModel.password" prefix-icon="el-icon-key" show-password
            placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="repeatPwd" class="is-required">
          <el-input v-model="addOrEditModel.repeatPwd" prefix-icon="el-icon-key" show-password
            placeholder="请重复登录密码"></el-input>
        </el-form-item>
        <el-form-item class="is-required">
          <el-button class="m-l-10" type="primary" :loading="showLoading" :disabled="showLoading"
            @click="onEditPwdSubmit">确
            定</el-button>
          <el-button @click="dialogEditAdminPwdFormVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 实名认证 -->
    <div class="lockScreen" v-if="dialogRealNameAuthVisible">
      <RealNameAuth :sendId="currentKey" @hide="dialogRealNameAuthVisible = false"></RealNameAuth>
    </div>
    <!-- 余额变动记录 -->
    <!-- 累积收益 -->
    <el-dialog title="余额变动记录" :visible.sync="dialogBalanceChangeListVisible" :modal="false" :destroy-on-close="true"
      fullscreen>
      <BalanceChangeList :userId="selectUserId" v-if="dialogBalanceChangeListVisible" />
    </el-dialog>
    <!-- 提现收益 -->
    <el-dialog title="提现记录" :visible.sync="dialogWithdrawalListVisible" :modal="false" :destroy-on-close="true"
      fullscreen>
      <WithdrawalList :userId="selectUserId" :withdrawableIncome="selectWithdrawableIncome" v-if="dialogWithdrawalListVisible" />
    </el-dialog>


  </div>
</template>
<script>
import { getList } from '@/tools/getList.js'
import adminManager from "@/request/adminManager.js";
import RealNameAuth from '@/components/page/agent/sub/RealNameAuth.vue'
import BalanceChangeList from "@/components/page/user/BalanceChangeList.vue";
import WithdrawalList from "@/components/page/user/WithdrawalList.vue";

export default {
  mixins: [getList],
  components: {
    RealNameAuth,
    BalanceChangeList,
    WithdrawalList,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
        if (regex.test(value)) {
          callback()
        } else {
          callback(new Error('请输入6到20位的数字字母混合密码'))
        }
      }
    }
    var validateRepeatPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else {
        var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
        if (regex.test(value)) {
          if (value === this.addOrEditModel.password) {
            callback()
          } else {
            callback(new Error('两次密码不一致'))
          }
        } else {
          callback(new Error('请输入6到20位的数字字母混合密码'))
        }
      }
    }
    var validateEmail = (rule, value, callback) => {
      if (value) {
        var regex = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
        if (regex.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱'))
        }
      } else {
        callback()
      }
    }
    return {
      requst: {
        module: adminManager,
        url: 'sysUserListv',
        addUrl: 'sysUserSave',
        updateUrl: 'sysUserUpdate',
        delUrl: 'sysUserRemove',
        keyId: 'userId'
      },
      query: {
        username: '', //搜索词
        roleId: 65,   //角色ID
      },
      rolelist: [],
      branchList: [],
      initModel: {
        userId: 0,
        username: '',//登录名
        name: '',//用户真实姓名
        password: '',//
        repeatPwd: '',//密码
        // deptId: 0,//
        // deptName: '',
        email: '',//邮箱
        mobile: '',//手机号
        status: 1,//状态 0:禁用，1:正常
        roleIds: '',//角色id 英文逗号拼接
      },
      addOrEditRules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, validator: validatePass, trigger: 'blur' }],
        repeatPwd: [{ required: true, validator: validateRepeatPass, trigger: 'blur' }],
        email: [{ required: false, validator: validateEmail, trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        roleIds: [{ required: true, message: '请选择角色', trigger: 'blur' }],

      },
      checkList: [],
      checkBranchList: [],
      //修改密码
      dialogEditAdminPwdFormVisible: false,
      addOrEditModel: {},
      addOrEditDialog: false, //添加、编辑弹窗
      //选择
      currentKey: 1,
      dialogSelectVisible: false,
      // 
      dialogRealNameAuthVisible: false,
      // 
      selectUserId: 0,
      dialogBalanceChangeListVisible: false,
      dialogWithdrawalListVisible: false,
      selectWithdrawableIncome:0,
    }
  },
  watch: {
    checkList(val) {
      console.log(val)
      this.addOrEditModel.roleIds = val.join(',')
    },
    checkBranchList(val) {
      console.log(val)
      this.addOrEditModel.branchCode = val.join(',')
    }
  },
  filters: {
    formatRule(val = []) {
      return val.filter(i => i.had).map(o => o.roleName).join(',') || '--'
    }
  },
  created: async function () {
    if (this.initModel) this.addOrEditModel = JSON.parse(JSON.stringify(this.initModel));
    // this.getRoleList();
  },
  methods: {
    onAdd() {
      this.addOrEditDialog = true
      this.checkList = [65]
      // this.addOrEditModel.roleIds = "1"
    },
    // async getRoleList () {
    //   this.rolelist = []
    //   try {
    //     let { code, data } = await adminManager.sysRoleListv()
    //     if (code === 200) {
    //       this.rolelist = data
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    // 点击编辑信息
    async clickEditInfo(row) {
      try {
        this.addOrEditModel = this.$util.assignObj(this.addOrEditModel, row)
        console.log('addOrEditModel', this.addOrEditModel, row);
        this.checkList = row.roleList.filter(i => i.had).map(o => o.roleId)
        this.addOrEditDialog = true
        // this.$refs["addOrEditModel"].clearValidate();
      } catch (error) {
        console.log(error)
      }
    },

    // 点击删除信息
    async clickDelInfo(row, index) {
      try {
        let { code, message } = await this.requst.module[this.requst.delUrl]({ id: row[this.requst.keyId] });
        if (code === 200) {
          this.$message.success(message);
          this.delRefresh(index);
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 点击添加、编辑----确认按钮
    clickAddOrEditConfirm() {
      this.$refs.addOrEditModel.validate(async (valid) => {
        if (valid) {
          try {
            this.showLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.addOrEditModel))
            let data = await this.requst.module[this.addOrEditModel[this.requst.keyId] > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
            if (data.code == 200) {
              this.$message({ message: data.message, type: 'success' });
              this.refreshData() //刷新列表数据
              this.resetAddOrEditData() //清除添加、编辑数据
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.showLoading = false
          }

        }
      })
    },
    // 清除添加/编辑数据
    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.addOrEditModel = JSON.parse(JSON.stringify(this.initModel));
      this.checkList = [];
      this.initModel = {
        userId: 0,
        username: '',//登录名
        name: '',//用户真实姓名
        password: '',//
        repeatPwd: '',//密码
        // deptId: 0,//
        // deptName: '',
        email: '',//邮箱
        mobile: '',//手机号
        status: 1,//状态 0:禁用，1:正常
        roleIds: '',//角色id 英文逗号拼接
      };
    },
    //修改密码
    onEditAdminPwd(row) {
      this.addOrEditModel.userId = row.userId
      this.addOrEditModel.password = '';
      this.addOrEditModel.repeatPwd = '';
      this.dialogEditAdminPwdFormVisible = true
    },
    async onEditPwdSubmit() {
      this.$refs.editPwd.validate(async valid => {
        if (valid) {
          try {
            this.showLoading = true
            let { code, message } = await adminManager.sysUserAdminResetPwd({ pwdNew: this.addOrEditModel.password, 'userDO.userId': this.addOrEditModel.userId })
            if (code === 200) {
              this.$message.success(message);
              this.refreshData() //刷新列表数据
              this.resetAddOrEditData() //清除添加、编辑数据
              this.dialogEditAdminPwdFormVisible = false
            }
          } finally {
            this.showLoading = false;
          }
        }
      })
    },

    // 选择代理商
    selectAgentHandle(row) {
      this.$emit('changeSelectAgentInfo', row)
    },
    // 实名认证
    clickAuthInfo(row) {
      this.dialogRealNameAuthVisible = true
    },
    // 累积收益
    userAccumulatedIncomeHandle(row) {
      this.handlePermission()
      if (this.permission.lookBalanceChangeList) {
        this.selectUserId = row.userId;
        this.dialogBalanceChangeListVisible = true;
      } else {
        this.$message.error('您没有权限查看')
      }
    },
    // 提现收益
    userWithdrawalIncomeHandle(row) {
      this.handlePermission()
      if (this.permission.lookWithdrawalList) {
        this.selectUserId = row.userId;
        this.selectWithdrawableIncome = row.withdrawableIncome?row.withdrawableIncome:0
        // this.selectWithdrawableIncome = 55
        this.dialogWithdrawalListVisible = true;
      } else {
        this.$message.error('您没有权限查看')
      }
    },


  }
}
</script>
<style scoped>
.pageblock {
  text-align: right;
  margin-top: 5px;
}

.lockScreen {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
</style>
