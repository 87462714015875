<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item v-if="!(this.userId > 0)">
                    <el-select size="small" v-model="query.userType" placeholder="请选择用户类型" clearable>
                        <el-option v-for="item in userTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size="small" v-model="query.withdrawalStatus" placeholder="请选择状态" clearable>
                        <el-option v-for="item in withdrawalStatusList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item>
                    <el-select size="small" v-model="query.changeType" placeholder="请选择变动类型" clearable>
                        <el-option v-for="item in changeTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <!--  代理商申请提现 -->
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.addWithdrawal"
                        @click="onApplyWithdrawal">申请提现</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'withdrawalRecordId'"
                v-loading="showLoading" :height="tableHeight">
                <el-table-column prop="withdrawalRecordId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="nickName" label="用户名称" align="center"></el-table-column>
                <!-- <el-table-column prop="isIncome" label="是否收入" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isIncome == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column> -->
                <el-table-column prop="userType" label="用户类型" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.userType * 1 | getStateName(userTypeList) }}
                    </template>
                </el-table-column>
                <el-table-column prop="beforeBalance" label="提现前余额" min-width="100" align="center"></el-table-column>
                <el-table-column prop="withdrawalAmount" label="提现金额" align="center"></el-table-column>
                <el-table-column prop="afterBalance" label="提现后余额" min-width="100" align="center"></el-table-column>
                <el-table-column prop="withdrawalTime" label="提现时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.withdrawalTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="withdrawalStatus" label="审核状态" align="center" min-width="85">
                    <template slot-scope="scope">
                        {{ scope.row.withdrawalStatus | getStateName(withdrawalStatusList) }}
                    </template>
                </el-table-column>
                <el-table-column prop="audiFailRemark" label="未通过原因" min-width="85" align="center">
                    <template slot-scope="scope">
                        <span @click="rowContent(scope.row)" v-if="scope.row.withdrawalStatus == 3">
                            <el-link type="primary">查看</el-link>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="withdrawalTime" label="审核时间" align="center" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.withdrawalTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100px" align="center" fixed="right"
                    v-if="permission.auditWithdrawal">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="审核" placement="top"
                            v-if="permission.auditWithdrawal && scope.row.withdrawalStatus == 1">
                            <el-button class="success" type="text" icon="el-icon-s-check"
                                @click="auditWithdrawalHandle(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
        <!-- 提现审核 -->
        <el-dialog title="提现审核" :visible.sync="dealDialog" :modal="false" :destroy-on-close="true" fullscreen
            @close="resetAddOrEditData">
            <el-form label-width="140px" ref="dealForm" :model="dealForm" :rules="addRules">
                <el-form-item label="审核状态">
                    <el-select size="small" v-model="dealForm.withdrawalStatus" @change="changeStatesHandle">
                        <el-option v-for="item in auditStatusList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核失败原因" prop="audiFailRemark" v-if="dealForm.withdrawalStatus == 3">
                    <el-input type="textarea" :rows="3" size="medium" v-model="dealForm.audiFailRemark"
                        placeholder="请输入审核失败原因"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="dealBtnLoading" @click="clickDealConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 申请提现 -->
        <el-dialog title="申请提现" :visible.sync="applyDialog" :modal="false" :destroy-on-close="true" fullscreen
            @close="resetAddOrEditData">
            <el-form label-width="140px" ref="applyForm" :model="applyForm" :rules="addRules">
                <el-form-item label="提现金额" prop="money">
                    <el-input type="number" size="medium" v-model="applyForm.money" placeholder="请输入提现金额"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="dealBtnLoading" @click="clickApplyConfirm">确定</el-button>
            </div>
        </el-dialog>



    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import userManager from "@/request/userManager.js";
import { withdrawalModule } from "@/request/userManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import adminManager from "@/request/adminManager.js";

export default {
    mixins: [getList],
    props: ["userId", "withdrawableIncome"],
    components: {
        ShowContent,
    },
    data() {
        var validateChangeMoney = (rule, value, callback) => {
            if (value === '' || value == 0) {
                callback(new Error('请输入提现金额'))
            } else if (this.currentWithdrawableIncome < value * 1) {
                callback(new Error('提现金额不能大于可提现金额'+this.currentWithdrawableIncome+'元'))
            } else {
                callback()
            }
        }
        return {
            requst: {
                module: userManager,
                url: 'withdrawalList',
            },
            query: {
                userId: this.userId ? this.userId : '',
                userType: '',
            },
            // 用户类型（2美容师、4主管、3代理商、1总部）
            userTypeList: [
                { value: 1, label: "总部", },
                { value: 2, label: "美容师", },
                { value: 3, label: "代理商", },
                { value: 4, label: "主管", },
            ],
            // 状态（0：未审核，1：已审核，2：已打款）
            withdrawalStatusList: [
                { value: 0, label: "未审核", },
                { value: 1, label: "已审核", },
                { value: 2, label: "已打款", },
                { value: 3, label: "审核失败", },
            ],
            auditStatusList: [
                { value: 1, label: "审核通过", },
                // { value: 2, label: "已打款", },
                { value: 3, label: "审核失败", },
            ],
            isIncomeList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            dialogSeeContent: false,
            rowInfo: {
                title: '',
                content: '',
            },
            //
            dealDialog: false,
            dealBtnLoading: false,
            dealForm: {
                withdrawalRecordId: 0,
                audiFailRemark: '',
                withdrawalStatus: 1,
            },
            addRules: {
                audiFailRemark: [
                    { required: true, message: '请输入审核失败原因', trigger: 'blur' },
                ],
                money: [
                    { required: true, message: '请输入提现金额', trigger: 'blur' },
                    { validator: validateChangeMoney, trigger: 'blur' },
                ],
            },
            //
            applyDialog: false,
            applyForm: {
                money: '',
            },
            currentWithdrawableIncome: this.withdrawableIncome,


        };
    },
    created() {
        this.handlePermission()
    },
    methods: {
        rowContent(row) {
            this.rowInfo.title = "未通过原因";
            this.rowInfo.content = row.audiFailRemark;
            this.dialogSeeContent = true;
        },
        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickDealConfirm() {
            this.$refs.dealForm.validate(async (valid) => {
                if (valid) {
                    this.dealBtnLoading = true
                    let dataValue = JSON.parse(JSON.stringify(this.dealForm))
                    await withdrawalModule.auditWithdrawal(dataValue).then((response) => {
                        this.dealBtnLoading = false;
                        switch (response.data.code) {
                            case 200:
                                this.$message({ message: response.data.message, type: 'success', })
                                this.$emit('success')
                                this.refresCurrentPagehData()
                                this.resetAddOrEditData()
                                break;
                            default:
                                this.$message({
                                    message: response.data.message,
                                    type: 'error'
                                });
                                // this.$message.error(response.data.message);
                                break;
                        }
                    }).catch((error) => {
                        //
                        this.dealBtnLoading = false;
                    });
                }
            })
        },
        changeStatesHandle() {
            this.dealForm.audiFailRemark = ''
        },
        // 清除添加数据
        resetAddOrEditData() {
            this.dealDialog = false
            this.dealForm.withdrawalRecordId = 0
            this.dealForm.audiFailRemark = ''
            this.dealForm.withdrawalStatus = 1

            this.applyDialog = false
            this.applyForm = {
                money: '',
            }
        },
        // 审核
        auditWithdrawalHandle(row) {
            this.dealForm.withdrawalRecordId = row.withdrawalRecordId
            this.dealDialog = true
        },
        // ------------- 申请提现 -------------
        onApplyWithdrawal() {
            this.applyDialog = true
        },
        clickApplyConfirm() {
            this.$refs.applyForm.validate(async (valid) => {
                if (valid) {
                    this.dealBtnLoading = true
                    let dataValue = JSON.parse(JSON.stringify(this.applyForm))
                    await adminManager.addWithdrawal(dataValue).then((response) => {
                        this.dealBtnLoading = false;
                        switch (response.data.code) {
                            case 200:
                                this.$message({ message: response.data.message, type: 'success', })
                                this.$emit('success')
                                this.refresCurrentPagehData()
                                this.resetAddOrEditData()
                                break;
                            default:
                                this.$message({
                                    message: response.data.message,
                                    type: 'error'
                                });
                                // this.$message.error(response.data.message);
                                break;
                        }
                    }).catch((error) => {
                        //
                        this.dealBtnLoading = false;
                    });
                }
            })
        },




    },


};
</script>

<style scoped></style>
