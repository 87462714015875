var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "page-container" }, [
        _c(
          "div",
          { staticClass: "search-box" },
          [
            _c(
              "el-form",
              { staticClass: "fr-s-s", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  [
                    _c("el-input", {
                      attrs: {
                        size: "small",
                        clearable: "",
                        placeholder: "请输入用户名",
                      },
                      model: {
                        value: _vm.query.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "username", $$v)
                        },
                        expression: "query.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          plain: "",
                          type: "primary",
                          loading: _vm.showLoading,
                        },
                        on: { click: _vm.refreshData },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _vm.permission.saveAgent
                  ? _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              plain: "",
                              type: "primary",
                              loading: _vm.showLoading,
                            },
                            on: { click: _vm.onAdd },
                          },
                          [_vm._v("添加")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.showLoading,
                    expression: "showLoading",
                  },
                ],
                ref: "table",
                attrs: {
                  data: _vm.tableData,
                  border: "",
                  "row-key": "aid",
                  height: _vm.tableHeight,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "advertID",
                    label: "编号",
                    align: "center",
                    width: "60",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.$index + 1))]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "姓名",
                    "min-width": "120",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.row.name || "--"))]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "username",
                    label: "用户名",
                    "min-width": "120",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.row.username || "--"))]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "mobile",
                    label: "手机号",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.row.mobile || "--"))]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "email",
                    label: "邮箱",
                    "min-width": "160",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.row.email || "--"))]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "accumulatedIncome",
                    label: "累积收益",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.userAccumulatedIncomeHandle(
                                    scope.row
                                  )
                                },
                              },
                            },
                            [
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(
                                  _vm._s(
                                    scope.row.accumulatedIncome
                                      ? scope.row.accumulatedIncome
                                      : 0
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "withdrawableIncome",
                    label: "可提现收益",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { attrs: { type: "primary" } }, [
                            _vm._v(
                              _vm._s(
                                scope.row.withdrawableIncome
                                  ? scope.row.withdrawableIncome
                                  : 0
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "withdrawalIncome",
                    label: "提现收益",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.userWithdrawalIncomeHandle(
                                    scope.row
                                  )
                                },
                              },
                            },
                            [
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(
                                  _vm._s(
                                    scope.row.withdrawalIncome
                                      ? scope.row.withdrawalIncome
                                      : 0
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "roleSigns",
                    label: "角色",
                    "min-width": "120",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            _vm._s(_vm._f("formatRule")(scope.row.roleList))
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "是否可用", width: "80", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("i", {
                            class: [
                              scope.row.status == 1
                                ? "el-icon-check"
                                : "el-icon-close",
                            ],
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    width: "140",
                    fixed: "right",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.permission.updateAgent
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "编辑",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "success",
                                    attrs: {
                                      icon: "el-icon-edit-outline",
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickEditInfo(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.permission.removeAgent
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "删除",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: "您确定要删除么？",
                                        icon: "el-icon-info",
                                        "icon-color": "red",
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.clickDelInfo(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "danger",
                                        attrs: {
                                          slot: "reference",
                                          icon: "el-icon-delete",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.permission.resetPwd
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "修改密码",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "success",
                                    attrs: {
                                      icon: "el-icon-key",
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEditAdminPwd(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.permission.selectAgent
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "选择",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "success",
                                    attrs: {
                                      icon: "el-icon-circle-check",
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectAgentHandle(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("el-pagination", {
              ref: "pagination",
              attrs: {
                "current-page": _vm.query.offset,
                "page-sizes": [10, 30, 50, 100],
                "page-size": _vm.query.limit,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.pageTotal,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.addOrEditModel.userId ? "编辑" : "添加"}代理商`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addOrEditModel",
              staticClass: "form-wrap",
              attrs: {
                "label-position": "left",
                "label-width": "100px",
                model: _vm.addOrEditModel,
                rules: _vm.addOrEditRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-user",
                      placeholder: "请输入姓名",
                    },
                    model: {
                      value: _vm.addOrEditModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "name", $$v)
                      },
                      expression: "addOrEditModel.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-user",
                      placeholder: "请输入用户名",
                    },
                    model: {
                      value: _vm.addOrEditModel.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "username", $$v)
                      },
                      expression: "addOrEditModel.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "11",
                      oninput: "value=value=value.replace(/[^0-9]/g,'')",
                      "prefix-icon": "el-icon-phone",
                      placeholder: "请输入手机号",
                    },
                    model: {
                      value: _vm.addOrEditModel.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "mobile", $$v)
                      },
                      expression: "addOrEditModel.mobile",
                    },
                  }),
                ],
                1
              ),
              !_vm.addOrEditModel.userId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "登录密码", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          "prefix-icon": "el-icon-key",
                          "show-password": "",
                          placeholder: "请输入登录密码",
                        },
                        model: {
                          value: _vm.addOrEditModel.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.addOrEditModel, "password", $$v)
                          },
                          expression: "addOrEditModel.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.addOrEditModel.userId
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "重复密码", prop: "repeatPwd" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          "prefix-icon": "el-icon-key",
                          "show-password": "",
                          placeholder: "请重复登录密码",
                        },
                        model: {
                          value: _vm.addOrEditModel.repeatPwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.addOrEditModel, "repeatPwd", $$v)
                          },
                          expression: "addOrEditModel.repeatPwd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-message",
                      placeholder: "请输入邮箱",
                    },
                    model: {
                      value: _vm.addOrEditModel.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "email", $$v)
                      },
                      expression: "addOrEditModel.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否可用", required: "" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": 1, "false-label": 0 },
                    model: {
                      value: _vm.addOrEditModel.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "status", $$v)
                      },
                      expression: "addOrEditModel.status",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.showLoading },
                      on: { click: _vm.clickAddOrEditConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-l-10",
                      on: { click: _vm.resetAddOrEditData },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `修改密码`,
            visible: _vm.dialogEditAdminPwdFormVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditAdminPwdFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPwd",
              attrs: {
                "label-width": "120px",
                model: _vm.addOrEditModel,
                rules: _vm.addOrEditRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "登录密码", prop: "password" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-key",
                      "show-password": "",
                      placeholder: "请输入登录密码",
                    },
                    model: {
                      value: _vm.addOrEditModel.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "password", $$v)
                      },
                      expression: "addOrEditModel.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "重复密码", prop: "repeatPwd" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-key",
                      "show-password": "",
                      placeholder: "请重复登录密码",
                    },
                    model: {
                      value: _vm.addOrEditModel.repeatPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "repeatPwd", $$v)
                      },
                      expression: "addOrEditModel.repeatPwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "is-required" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "m-l-10",
                      attrs: {
                        type: "primary",
                        loading: _vm.showLoading,
                        disabled: _vm.showLoading,
                      },
                      on: { click: _vm.onEditPwdSubmit },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogEditAdminPwdFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialogRealNameAuthVisible
        ? _c(
            "div",
            { staticClass: "lockScreen" },
            [
              _c("RealNameAuth", {
                attrs: { sendId: _vm.currentKey },
                on: {
                  hide: function ($event) {
                    _vm.dialogRealNameAuthVisible = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "余额变动记录",
            visible: _vm.dialogBalanceChangeListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogBalanceChangeListVisible = $event
            },
          },
        },
        [
          _vm.dialogBalanceChangeListVisible
            ? _c("BalanceChangeList", { attrs: { userId: _vm.selectUserId } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提现记录",
            visible: _vm.dialogWithdrawalListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogWithdrawalListVisible = $event
            },
          },
        },
        [
          _vm.dialogWithdrawalListVisible
            ? _c("WithdrawalList", {
                attrs: {
                  userId: _vm.selectUserId,
                  withdrawableIncome: _vm.selectWithdrawableIncome,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }